import React, { useEffect, useState, useRef } from "react";
import { SellerNav } from "./DealerNav";
import { useParams } from "react-router-dom"; // import useParams
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import api from "../api";
dayjs.extend(utc);
dayjs.extend(timezone);
export const CarDetails = () => {
  const { Vehicle_Id } = useParams(); // Get the ID from the URL slug
  const dealerId = sessionStorage.getItem("user_id");
  const [appreport, setAppeport] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [bidPrice, setBidPrice] = useState("");
  const [loading, setLoading] = useState(false);
  const [vehicleData, setVehicleData] = useState(null);
  const [sellerid, setSeller] = useState("");
  const [vechicleid, setVehicleId] = useState("");
  const [highestbids, setHighestBid] = useState("");
  const [location, setLocation] = useState("");
  const [vin, setVin] = useState("");
  const [year, setYear] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [trim, setTrim] = useState("");
  const [mileage, setMileage] = useState("");
  const numericMileage = Number(mileage);
  const [color, setColor] = useState("");
  const [keys, setkeys] = useState("");
  const [settire, setSetTire] = useState("");
  const [windowtint, setWindowTint] = useState("");
  const [aftermarketexhaust, setAftermarketExhaust] = useState("");
  const [aftermarkrims, setAftermarkRims] = useState("");
  const [roofrack, setRoofRack] = useState("");
  const [remotestarter, setRemoteStarter] = useState("");
  const [aftermarkstereo, setAftermarkStereo] = useState("");
  const [aftermarketspoiler, setAftermarketSpoiler] = useState("");
  const [minordamage, setMinorDamage] = useState("");
  const [interiorimage, setInteriorImage] = useState("");
  const [interiorimagetwo, setInteriorImageTwo] = useState("");
  const [interiorimagethree, setInteriorImageThree] = useState("");
  const [interiorimagefour, setInteriorImageFour] = useState("");
  const [exteriorimage, setExteriorImage] = useState("");
  const [exteriorimagetwo, setExteriorImageTwo] = useState("");
  const [exteriorimagethree, setExteriorImageThree] = useState("");
  const [exteriorimagefour, setExteriorImageFour] = useState("");
  const [dashboardimage, setDashboardImage] = useState("");
  const [dashboardimagetwo, setDashboardImageTwo] = useState("");
  const [dashboardimagethree, setDashboardImageThree] = useState("");
  const [dashboardimagefour, setDashboardImageFour] = useState("");
  const [rimsimage, setRimsImage] = useState("");
  const [rimsimagetwo, setRimsImageTwo] = useState("");
  const [rimsimagethree, setRimsImageThree] = useState("");
  const [rimsimagefour, setRimsImageFour] = useState("");
  const [fadingpaints, setFadingPaints] = useState("");
  const [rust, setRust] = useState("");
  const [haildamage, setHailDamage] = useState("");
  const [extmintcondition, setExtMintCondition] = useState("");
  const [dents, setDents] = useState("");
  const [ripsOrTears, setRipsOrTears] = useState(false);
  const [visibleStain, setVisibleStain] = useState(false);
  const [strongSmell, setStrongSmell] = useState(false);
  const [damagedSystems, setDamagedSystems] = useState(false);
  const [IntmintCondition, setIntMintCondition] = useState(false);
  const [smokeInVehicle, setSmokeInVehicle] = useState(false);
  const [hasOriginalRims, setHasOriginalRims] = useState(null);
  const [tireReplacement, setTireReplacement] = useState(null);
  const [vehicleDrivable, setVehicleDrivable] = useState(null);
  const [crackOnWindshield, setCrackOnWindshield] = useState(null);
  const [extendedWarranty, setExtendedWarranty] = useState(null);
  const [tradeInInterest, setTradeInInterest] = useState(null);
  const [hasWinterTires, setHasWinterTires] = useState(null);
  const [sellTiming, setSellTiming] = useState("");
  const [DonotNeedCar, setDonotNeedCar] = useState("");
  const [MechElectIssues, setMechElectIssues] = useState("");
  const [DownSize, setDownSize] = useState("");
  const [BuyAnotherCar, setBuyAnotherCar] = useState("");
  const [carCondition, setCarCondition] = useState("");
  const [accidentclaims, setAccidentClaims] = useState("");
  const [howmuchclaims, setHowMuchClaims] = useState("");
  const [carrims, setCarRims] = useState("");
  const [stockrim, setStockRim] = useState("");
  const [issuevichle, setIssueVichle] = useState("");
  const [issuevichledecs, setIssueVichleDecs] = useState("");
  const [carmodify, setCarMdify] = useState("");
  const [carmodifydecs, setCarMdifyDecs] = useState("");
  const [financed, setFinanced] = useState("");
  const [financeddesc, setFinancedDesc] = useState("");
  const [getbidprice, setGetBidPrice] = useState("");
  const [vechiclevideo, setVechicleVideo] = useState("");
  const [firmprice, setFirmPrice] = useState("");
  console.log(firmprice)
  const [sellertransmission, setSellerTransmission] = useState("");
  const initialBidPrice = getbidprice ? `$${getbidprice}` : "";
  const [targetTime, setTargetTime] = useState(null);
  const [countdown, setCountdown] = useState("");
  const [listedDate, setListedDate] = useState("");
  const [hours, sethours] = useState("");
  const [minutes, setminutes] = useState("");
  const [seconds, setseconds] = useState("");
  const [isTimeUp, setIsTimeUp] = useState(false);
  const [vechiclenotes, setVechicleNotes] = useState("");
  const [vehicledrivabledesc, setVehicleDrivableDes] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [mins, setminutesvar] = useState("");
  const [ownership, setOwnerShip] = useState("");
  const [drivingl, setDrivingL] = useState("");
  const [minutesMap, setMinutesMap] = useState();
  const [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const intervalRef = useRef(null);
  const remainingTimeRef = useRef(0);
  const [topBids, setTopBids] = useState([]);
  const [currentBid, setCurrentBid] = useState(null);
  const [previousBid, setPreviousBid] = useState(null);
  const [shownow, setShowNow] = useState(false);
  const [newBidAmount, setNewBidAmount] = useState(""); // New bid input field
  const [agentDetails, setAgentDetails] = useState(null); // Store agent details
  const [deliveryModeShow, setDeliveryModeShow] = useState(false);
  const [deliveryMode, setDeliveryMode] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [auctionOverData, setAuctionOverData] = useState("");
  const [province, setProvince] = useState("");
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ type: "", src: "" });
  const [pricemeet, setPriceMeet] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [deliveryModeMessage, SetdeliveryModeMessage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [message, setMessage] = useState("");
  const [messages, setMessageBid] = useState("");
  const [evaluationreport, setevaluationreport] = useState("");
  const [selectedFileCar, setSelectedFileCar] = useState(null);
  const [getPrice, setGetPrice] = useState("");
  const [averageprice, setGetaveragePrice] = useState("");
  const [belowprice, setGetbelowPrice] = useState("");
  const [CarfaxReportUrl, setCarfaxReportUrl] = useState("");
  const [hasline, setHasLiens] = useState("");
  const [imageIndex, setImageIndex] = useState(0); // Track the current image index
  const [setLowPrice, setLow_Price] = useState("");
  const [setAveragePrice, setAverage_Price] = useState("");
  const [setAwesomePrice, setAwesome_Price] = useState("");
  const [CarfaxReportHideLiensUrl, setCarfaxReportHideLiensUrl] = useState("");
  const [highestbid, SetHighFetchDataalwsys] = useState("");
  const [relistGroups, setRelistGroups] = useState({});
  const [bidmessage, setBidmessage] = useState({ text: "", color: "" });
  const [isBidTimeClosed, setIsBidTimeClosed] = useState(false); 

  const imageList = [
    interiorimage &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimage}`,
    interiorimagetwo &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagetwo}`,
    interiorimagethree &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagethree}`,
    interiorimagefour &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagefour}`,
    exteriorimage &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimage}`,
    exteriorimagetwo &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagetwo}`,
    exteriorimagethree &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagethree}`,
    exteriorimagefour &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagefour}`,
    dashboardimage &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimage}`,
    dashboardimagetwo &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagetwo}`,
    dashboardimagethree &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagethree}`,
    dashboardimagefour &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagefour}`,
    rimsimage &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimage}`,
    rimsimagetwo &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagetwo}`,
    rimsimagethree &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagethree}`,
    rimsimagefour &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagefour}`,
  ].filter(Boolean); // This filters out any falsy values like null or undefined

  const openModal = (type, src) => {
    setModalContent({ type, src });
    setImageIndex(imageList.indexOf(src)); // Set the image index when the modal is opened
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    // Function to handle keyboard navigation
    const handleKeyDown = (e) => {
      if (e.key === "ArrowLeft") {
        navigateImage("prev");
      } else if (e.key === "ArrowRight") {
        navigateImage("next");
      }
    };

    // Add event listener for keydown when modal is open
    if (isModalOpen) {
      document.addEventListener("keydown", handleKeyDown);
    }

    // Clean up the event listener when modal is closed
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isModalOpen, imageIndex]);

  const navigateImage = (direction) => {
    if (direction === "prev") {
      setImageIndex((prevIndex) =>
        prevIndex === 0 ? imageList.length - 1 : prevIndex - 1
      );
    } else if (direction === "next") {
      setImageIndex((prevIndex) =>
        prevIndex === imageList.length - 1 ? 0 : prevIndex + 1
      );
    }
    setModalContent({ ...modalContent, src: imageList[imageIndex] });
  };

  useEffect(() => {
    if (
      Vehicle_Id &&
      (interiorimage ||
        interiorimagetwo ||
        interiorimagethree ||
        interiorimagefour ||
        exteriorimage ||
        dashboardimage ||
        dashboardimagetwo ||
        dashboardimagethree ||
        dashboardimagefour ||
        rimsimage ||
        rimsimagetwo ||
        rimsimagethree ||
        rimsimagefour)
    ) {
      initializeFlexSlider();
    }
  }, [
    Vehicle_Id,
    interiorimage,
    interiorimagetwo,
    interiorimagethree,
    interiorimagefour,
    exteriorimage,
    dashboardimage,
    rimsimage,
  ]);
  useEffect(() => {
    fetchData();
  }, []); // Dependency array
  const fetchData = async () => {
    try {
      const response = await api.get(
        `/dealer/onetimeloadonliveauction/${Vehicle_Id}/${dealerId}`
      );
      const vehicleData = response.data;
      console.log(vehicleData)
      setImageUrl(vehicleData.BadgeReport.Imageurl); // Set the image URL if available
      setMessage(vehicleData.BadgeReport.Message || ""); // Set the message if available
      setProvince(vehicleData.Carlocation.province); // Set the province if it's present in the response
      setAgentDetails(vehicleData.Agent[0]); // Set the first agent object
      const mode =
          vehicleData?.GetLastDeliveryMode?.Delivery_Mode || // Use GetLastDeliveryMode if available
          vehicleData?.GetLastDeliveryModeOnUpcomming?.data; // Fallback to GetLastDeliveryModeOnUpcomming
        if (mode) {
          setDeliveryMode(mode); // Set delivery mode if it exists
        } else {
          console.warn("No delivery mode found in API response.");
          setDeliveryMode(""); // Reset state if no delivery mode
        }
        setRelistGroups(vehicleData.OldBidsHistory);
      setSeller(vehicleData.vehicle.Seller_Id);
      setListedDate(vehicleData.vehicle.Listed_Date || "");
      const listedDate = vehicleData.vehicle.Listed_Date;
      setevaluationreport(vehicleData.vehicle.evaluation_report || "");
      setFirmPrice(vehicleData.vehicle.Reserve_Price);
      setLocation(vehicleData.vehicle.Location || "");
      setHighestBid(vehicleData.HighestBid);
      setVin(vehicleData.vehicle.VIN || "");
      setYear(vehicleData.vehicle.Year || "");
      setMake(vehicleData.vehicle.Make || "");
      setModel(vehicleData.vehicle.Model || "");
      setTrim(vehicleData.vehicle.Trim || "");
      setMileage(vehicleData.vehicle.Mileage || "");
      setColor(vehicleData.vehicle.Color || "");
      setkeys(vehicleData.vehicle.Car_Keys || "");
      setSetTire(vehicleData.vehicle["2_Sets_Of_Tire"] || "");
      setWindowTint(vehicleData.vehicle["Win_Tint"] || "");
      setAftermarketExhaust(vehicleData.vehicle["Aftermark_Exhaust"] || "");
      setAftermarkRims(vehicleData.vehicle["Aftermark_Rims"] || "");
      setRoofRack(vehicleData.vehicle["Roof_Rack"] || "");
      setRemoteStarter(vehicleData.vehicle["Remote_Start"] || "");
      setRemoteStarter(vehicleData.vehicle["Remote_Start"] || "");
      setAftermarkStereo(vehicleData.vehicle["Aftermark_Stereo"] || "");
      setAftermarketSpoiler(vehicleData.vehicle["Aftermark_Spoiler"] || "");
      setMinorDamage(vehicleData.vehicle["Minor_Damage"] || "");
      setInteriorImage(vehicleData.vehicle["Interior_Image"] || "");
      setInteriorImageTwo(vehicleData.vehicle["Interior_Image2"] || "");
      setInteriorImageThree(vehicleData.vehicle["Interior_Image3"] || "");
      setInteriorImageFour(vehicleData.vehicle["Interior_Image4"] || "");
      setExteriorImage(vehicleData.vehicle["Exterior_Image"] || "");
      setExteriorImageTwo(vehicleData.vehicle["Exterior_Image2"] || "");
      setExteriorImageThree(vehicleData.vehicle["Exterior_Image3"] || "");
      setExteriorImageFour(vehicleData.vehicle["Exterior_Image4"] || "");
      setDashboardImage(vehicleData.vehicle["Dashboard_Image"] || "");
      setDashboardImageTwo(vehicleData.vehicle["Dashboard_Image2"] || "");
      setDashboardImageThree(vehicleData.vehicle["Dashboard_Image3"] || "");
      setDashboardImageFour(vehicleData.vehicle["Dashboard_Image4"] || "");
      setRimsImage(vehicleData.vehicle["Rims_Image"] || "");
      setRimsImageTwo(vehicleData.vehicle["Rims_Image2"] || "");
      setRimsImageThree(vehicleData.vehicle["Rims_Image3"] || "");
      setRimsImageFour(vehicleData.vehicle["Rims_Image4"] || "");
      setFadingPaints(vehicleData.vehicle["Fading_Paints"] || "");
      setRust(vehicleData.vehicle["Rust"] || "");
      setHailDamage(vehicleData.vehicle["Hail_Damage"] || "");
      setExtMintCondition(vehicleData.vehicle["Ext_Mint_Condition"] || "");
      setDents(vehicleData.vehicle["Dents"] || "");
      setRipsOrTears(vehicleData.vehicle["Seats_Rips_Tears"] || "");
      setVisibleStain(vehicleData.vehicle["Seats_Visible_Stain"] || "");
      setStrongSmell(vehicleData.vehicle["Strong_Smell"] || "");
      setDamagedSystems(vehicleData.vehicle["Nav_Entmt_Ctrl_Dmg_Sys"] || "");
      setIntMintCondition(vehicleData.vehicle["Int_Mint_Condition"] || "");
      setSmokeInVehicle(vehicleData.vehicle["Smoke_In_Vehicle"] || "No");
      setHasOriginalRims(vehicleData.vehicle["Original_Factory_Rims"] || "No");
      setTireReplacement(vehicleData.vehicle["Tires_Repld_12_Months"] || "No");
      setVehicleDrivable(vehicleData.vehicle["Vehicle_Drivable"] || "No");
      setCrackOnWindshield(vehicleData.vehicle["Windshield_Crack"] || "No");
      setExtendedWarranty(vehicleData.vehicle["Extended_Warranty"] || "No");
      setTradeInInterest(vehicleData.vehicle["TradeIn_Interested"] || "No");
      setHasWinterTires(vehicleData.vehicle["Winter_Tires"] || "No");
      setVehicleDrivableDes(
        vehicleData.vehicle["vehicle_drivable_not_explanation"]
      );
      setSellTiming(vehicleData.vehicle["How_Soon_Ready_Sell"]);
      setDonotNeedCar(vehicleData.vehicle["Donot_Need_Car"] || "");
      setMechElectIssues(vehicleData.vehicle["Mech_Elect_Issues"] || "");
      setDownSize(vehicleData.vehicle["Downsize"] || "");
      setBuyAnotherCar(vehicleData.vehicle["Buy_Another_Car"] || "");
      setCarCondition(vehicleData.vehicle["Car_Condition"]);
      setAccidentClaims(vehicleData.vehicle["Accident_Claims"] || "No");
      setHowMuchClaims(vehicleData.vehicle["How_Much_Claims"] || "");
      setCarRims(vehicleData.vehicle["Car_Rims"]);
      setStockRim(vehicleData.vehicle["Stock_Rims"] || "No");
      setIssueVichle(vehicleData.vehicle["Any_Vehicle_Issues"] || "No");
      setIssueVichleDecs(vehicleData.vehicle["Vehicle_Issues_desc"] || "");
      setCarMdify(vehicleData.vehicle["Car_Modification"] || "No");
      setCarMdifyDecs(vehicleData.vehicle["Modification_desc"] || "");
      setFinanced(vehicleData.vehicle["Leased_Financed"] || "No");
      setFinancedDesc(vehicleData.vehicle["Financed_By"] || "");
      setVehicleId(vehicleData.vehicle["Vehicle_Id"]);
      setVechicleVideo(vehicleData.vehicle["Vehicle_video"]);
      setVechicleVideo(vehicleData.vehicle["Vehicle_video"]);
      setVechicleNotes(vehicleData.vehicle["Car_Notes"]);
      setSellerTransmission(vehicleData.vehicle["Transmission_Type"]);
      setCarfaxReportHideLiensUrl(
        vehicleData.vehicle["Carfax_Report_HideLiens_Url"] || ""
      );
      setOwnerShip(vehicleData.vehicle.Ownership_Doc);
      setDrivingL(vehicleData.vehicle.Driving_Licence_Doc);
      setSelectedFeatures(vehicleData.vehicle.Additional_Car_Feature);
      setSelectedFileCar(vehicleData.vehicle.evaluation_report);
      setCarfaxReportUrl(vehicleData.vehicle.Carfax_Report_Url);
      setLow_Price(vehicleData.vehicle.Low_Price);
      setAverage_Price(vehicleData.vehicle.Average_Price);
      setAwesome_Price(vehicleData.vehicle.Awesome_Price);
      setVin(vehicleData.vehicle.VIN || "");
      const vin = vehicleData.vehicle.VIN;
      setYear(vehicleData.vehicle.Year || "");
      const year = vehicleData.vehicle.Year;
      setModel(vehicleData.vehicle.Model || "");
      const model = vehicleData.vehicle.Model;
      setTrim(vehicleData.vehicle.Trim || "");
      const trim = vehicleData.vehicle.Trim;
      setMileage(vehicleData.vehicle.Mileage || "");
      const mileage = vehicleData.vehicle.Mileage;
      setMake(vehicleData.vehicle.Make || "");
      const make = vehicleData.vehicle.Make;
      if (vehicleData.vehicle["Has_Liens"] === 1) {
        setHasLiens("Has lien");
      }

      if (vehicleData.vehicle["Leased_Financed"] === 1) {
        setFinanced("Yes");
      }
      if (vehicleData.vehicle["Car_Modification"] === 1) {
        setCarMdify("Yes");
      }
      if (vehicleData.vehicle["Any_Vehicle_Issues"] === 1) {
        setIssueVichle("Yes");
      }
      if (vehicleData.vehicle["Stock_Rims"] === 1) {
        setStockRim("Yes");
      }
      if (vehicleData.vehicle["Accident_Claims"] === 1) {
        setAccidentClaims("Yes");
      }
      if (vehicleData.vehicle["Donot_Need_Car"] === 1) {
        setDonotNeedCar("Don’t Need The Car Anymore");
      }
      if (vehicleData.vehicle["Mech_Elect_Issues"] === 1) {
        setMechElectIssues(
          "Selling Because The Car Has Some Mechanical/ Electrical Issues"
        );
      }
      if (vehicleData.vehicle["Downsize"] === 1) {
        setDownSize("Selling To Downsize");
      }
      if (vehicleData.vehicle["Buy_Another_Car"] === 1) {
        setBuyAnotherCar("Selling To Buy Another Car");
      }
      if (vehicleData.vehicle["Winter_Tires"] === 1) {
        setHasWinterTires("Yes");
      }
      if (vehicleData.vehicle["TradeIn_Interested"] === 1) {
        setTradeInInterest("Yes");
      }
      if (vehicleData.vehicle["Extended_Warranty"] === 1) {
        setExtendedWarranty("Yes");
      }
      if (vehicleData.vehicle["Windshield_Crack"] === 1) {
        setCrackOnWindshield("Yes");
      }
      if (vehicleData.vehicle["Vehicle_Drivable"] === 1) {
        setVehicleDrivable("Yes");
      }
      if (vehicleData.vehicle["Tires_Repld_12_Months"] === 1) {
        setTireReplacement("Yes");
      }
      if (vehicleData.vehicle["Original_Factory_Rims"] === 1) {
        setHasOriginalRims("Yes");
      }
      if (vehicleData.vehicle["Original_Factory_Rims"] === 1) {
        setHasOriginalRims("Yes");
      }
      if (vehicleData.vehicle["Smoke_In_Vehicle"] === 1) {
        setSmokeInVehicle("Yes");
      }
      if (vehicleData.vehicle["2_Sets_Of_Tire"] === 1) {
        setSetTire("2 Sets of Tire");
      }
      if (vehicleData.vehicle["Win_Tint"] === 1) {
        setWindowTint("Window Tint");
      }
      if (vehicleData.vehicle["Aftermark_Exhaust"] === 1) {
        setAftermarketExhaust("Aftermarket Exhaust");
      }
      if (vehicleData.vehicle["Aftermark_Rims"] === 1) {
        setAftermarkRims("Aftermarket Rims");
      }
      if (vehicleData.vehicle["Roof_Rack"] === 1) {
        setRoofRack("Roof Rack");
      }
      if (vehicleData.vehicle["Remote_Start"] === 1) {
        setRemoteStarter("Remote Start");
      }
      if (vehicleData.vehicle["Aftermark_Stereo"] === 1) {
        setAftermarkStereo("Aftermarket Stereo");
      }
      if (vehicleData.vehicle["Aftermark_Spoiler"] === 1) {
        setAftermarketSpoiler("Aftermarket Spoiler");
      }
      if (vehicleData.vehicle["Minor_Damage"] === 1) {
        setMinorDamage("Minor Damage");
      }
      if (vehicleData.vehicle["Fading_Paints"] === 1) {
        setFadingPaints("Fading Paints");
      }
      if (vehicleData.vehicle["Rust"] === 1) {
        setRust("Rust");
      }
      if (vehicleData.vehicle["Hail_Damage"] === 1) {
        setHailDamage("Hail Damage");
      }
      if (vehicleData.vehicle["Ext_Mint_Condition"] === 1) {
        setExtMintCondition("Mint Condition");
      }
      if (vehicleData.vehicle["Dents"] === 1) {
        setDents("Dents");
      }
      if (vehicleData.vehicle["Int_Mint_Condition"] === 1) {
        setIntMintCondition("Mint Condition");
      }
      if (vehicleData.vehicle["Nav_Entmt_Ctrl_Dmg_Sys"] === 1) {
        setDamagedSystems("Damaged Systems");
      }
      if (vehicleData.vehicle["Strong_Smell"] === 1) {
        setStrongSmell("Strong Smell");
      }
      if (vehicleData.vehicle["Seats_Visible_Stain"] === 1) {
        setVisibleStain("Visible Stain On The Seats");
      }
      if (vehicleData.vehicle["Seats_Rips_Tears"] === 1) {
        setRipsOrTears("Rips Or Tears On The Seats");
      }
     
    
     
    } catch (error) {
      //console.error("Error fetching vehicle details:", error);
    }
  };

  const features = [
    settire,
    windowtint,
    aftermarketexhaust,
    roofrack,
    remotestarter,
    aftermarkstereo,
    aftermarketspoiler,
  ];
  const filteredFeatures = features.filter((feature) => feature);
  const featuresString = filteredFeatures.join(",");
  const featurestwo = [
    minordamage,
    fadingpaints,
    rust,
    haildamage,
    extmintcondition,
    dents,
  ];
  const filteredFeaturestwo = featurestwo.filter((feature) => feature);
  const featuresStringtwo = filteredFeaturestwo.join(",");
  const featuresthree = [
    ripsOrTears,
    visibleStain,
    strongSmell,
    damagedSystems,
    IntmintCondition,
  ];
  const filteredFeaturesthree = featuresthree.filter((feature) => feature);
  const featuresStringthree = filteredFeaturesthree.join(",");
  const initializeFlexSlider = () => {
    window.$(".flexslider").flexslider({
      animation: "slide",
      controlNav: "thumbnails",
      start: function (slider) {
        window.$("body").removeClass("loading");
      },
    });
  };
  const fetchDataEverrySecond = async () => {
    try {
      if (!firmprice) {
        console.error("Firm price is not set.");
        return;
      }
      const response = await api.get(
        `/dealer/handleliveauction/${Vehicle_Id}/${firmprice}`
      )
      const dataeverysecond = response.data;
      console.log("fetchDataEverrySecond",response.data)
      setAuctionOverData(dataeverysecond.auctionOver); // Store API result
      setCurrentBid(dataeverysecond.currentBid?.Bid_Amount);
      setPreviousBid(dataeverysecond.previousBid?.Bid_Amount);
      setTopBids(dataeverysecond.bidHistory.TopBids);
      SetHighFetchDataalwsys(dataeverysecond.highestBid);
      const data = dataeverysecond.reservePriceMet;
      if (data === true && !pricemeet) {
        setPriceMeet(true); // Price is met
      } else if (data === false && pricemeet) {
        setPriceMeet(false); // Price is no longer met
      }
      //time out
      const totalSeconds = Math.floor(dataeverysecond.auctionTimeoutDetails.counterseconds);
      if (intervalRef.current === null) {
        startCountdown(totalSeconds); // Start countdown only if it's not already running
      } else {
        remainingTimeRef.current = totalSeconds; // If already running, just update the remaining time
      }
    } catch (error) {
      //console.error("Error fetching vehicle details:", error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchDataEverrySecond();
    }, 2000);

    // Cleanup interval when component unmounts
    return () => clearInterval(intervalId);
  }, [firmprice]); // Dependency array ensures firmprice is up-to-date

  const startCountdown = (totalSeconds) => {
    let secondsRemaining = totalSeconds;
    remainingTimeRef.current = secondsRemaining;
  
    intervalRef.current = setInterval(() => {
      if (secondsRemaining <= 0) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
        setTimeLeft({ hours: 0, minutes: 0, seconds: 0 });
        setIsBidTimeClosed(true); // Set bid time closed when timer hits 0
        return;
      }
  
      secondsRemaining -= 1;
      remainingTimeRef.current = secondsRemaining;
  
      const hours = Math.floor(secondsRemaining / 3600);
      const minutes = Math.floor((secondsRemaining % 3600) / 60);
      const seconds = secondsRemaining % 60;
  
      setTimeLeft({ hours, minutes, seconds });
    }, 1000);
  };
  
 
  

  const handleBidPriceChange = (e) => {
    let value = e.target.value;

    // Remove non-numeric characters except for the period
    value = value.replace(/[^0-9.]/g, "");

    // Split the value into integer and decimal parts
    const parts = value.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Format the value with a dollar sign and rejoin parts
    const formattedValue = `$${parts.join(".")}`;
    setNewBidAmount(formattedValue);
  };

  const formatWithCommas = (value) => {
    // Convert the value to a float, format it with commas, and prepend the dollar sign
    if (isNaN(value) || value === null || value === undefined) {
      return "$0.00";
    }
    return `$${parseFloat(value).toLocaleString()}`;
  };

  

  const fetchDeliveryModePass = async () => {
    try {
      const response = await api.post(
        `/dealer/updatedeliverymode/${Vehicle_Id}/${dealerId}`,
        {
          delivery_mode: deliveryMode, // Pass delivery mode as payload
        }
      );
console.log("testde",response.data)
      SetdeliveryModeMessage("Delivery mode Added Successfully.");
      // console.log("Delivery mode updated:", response.data);
    } catch (error) {
      //console.error("Error updating delivery mode:", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const setAlert = async () => {
    try {
      // Making the API call with Vehicle_Id and dealerId
      const response = await api.post("/dealer/setalert", {
        vehicleid: Vehicle_Id,
        dealerid: dealerId,
      });

      //console.log("Set Alert Response:", response.data); // Logging for debugging
      alert("Alert set successfully!"); // You can change this to a more user-friendly message or UI feedback
      if (remainingTimeRef.current > 0) {
        startCountdown(remainingTimeRef.current); // Resume countdown from the remaining time
      }
    } catch (error) {
      //console.error("Error setting alert:", error);
      alert("Failed to set alert. Please try again."); // Error feedback
    }
  };
  const handleAddNewBid = async () => {
    if (isLoading) return; // Prevent duplicate API calls
    setIsLoading(true); // Start loading
  
    const dealerId = sessionStorage.getItem("user_id");
    const numericBidPrice = parseFloat(newBidAmount.replace(/[$,]/g, ""));
  
    // Validate inputs
    if (!newBidAmount || newBidAmount.trim() === "") {
      setMessageBid(
        <span style={{ color: "red" }}>Bid amount cannot be empty.</span>
      );
      setIsLoading(false);
      return;
    }
  
  
    setNewBidAmount(""); // Clear input after successful submission
  
    try {
      // Send the API request
      const response = await api.post("/dealer/dealerbidprice", {
        Bid_Amount: numericBidPrice,
        Dealer_Id: dealerId,
        Vehicle_Id: Vehicle_Id,
        delivery_mode: deliveryMode,
      });
      console.log("Bid submitted successfully:", response.data.message);
      const { message, status } = response.data;
      console.log("API Response:", response.data);

      setBidmessage({
        text: message,
        color: status ? "green" : "red",  // Green for success, red for failure
      });
    
    } catch (error) {
      console.error("Error while submitting the bid:", error);
      // Display error message if the API request fails
      setMessageBid(
        <span style={{ color: "red" }}>
          Failed to place your bid. Please try again.
        </span>
      );
    } finally {
      setIsLoading(false); // Stop loading state
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAddNewBid();
    }
  };
  return (
    <section class="car-details">
      <SellerNav />
      <div class="vehicle-detials-page mt-4">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 mb-lg-0 mb-4">
              <div class="bid-timing-panel">
                <h4>Time left to Bid:</h4>
                {timeLeft.hours} Hours {timeLeft.minutes} Minutes{" "}
                {timeLeft.seconds} Seconds
              </div>
              <div
                className={
                  vechiclevideo && vechiclevideo !== "null"
                    ? "col-md-12 p-0"
                    : "col-md-12 p-0 text-center"
                }
              >
                <div class="car-list-image">
                  <div class="flexslider">
                    <ul className="slides">
                      {interiorimage && (
                        <>
                          <li
                            data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimage}`}
                          >
                            <h3 className="vehicle-info-heading pt-3">
                              Interior Image
                            </h3>
                            <img
                              src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimage}`}
                              alt="Interior"
                              onClick={() =>
                                openModal(
                                  "image",
                                  `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimage}`
                                )
                              }
                              style={{
                                cursor: "pointer",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                              }}
                            />
                          </li>
                        </>
                      )}
                      {interiorimagetwo && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagetwo}`}
                        >
                          <h3 className="vehicle-info-heading pt-3">
                            Interior Image
                          </h3>
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagetwo}`}
                            alt="Interior"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagetwo}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {interiorimagethree && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagethree}`}
                        >
                          <h3 className="vehicle-info-heading pt-3">
                            Interior Image
                          </h3>
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagethree}`}
                            alt="Interior"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagethree}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {interiorimagefour && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagefour}`}
                        >
                          <h3 className="vehicle-info-heading pt-3">
                            Interior Image
                          </h3>
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagefour}`}
                            alt="Interior"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagefour}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {exteriorimage && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimage}`}
                        >
                          <h3 className="vehicle-info-heading pt-3">
                            Exterior Image
                          </h3>
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimage}`}
                            alt="Exterior"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimage}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {exteriorimagetwo && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagetwo}`}
                        >
                          <h3 className="vehicle-info-heading pt-3">
                            Exterior Image
                          </h3>
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagetwo}`}
                            alt="Exterior"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagetwo}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {exteriorimagethree && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagethree}`}
                        >
                          <h3 className="vehicle-info-heading pt-3">
                            Exterior Image
                          </h3>
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagethree}`}
                            alt="Exterior"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagethree}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {exteriorimagefour && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagefour}`}
                        >
                          <h3 className="vehicle-info-heading pt-3">
                            Exterior Image
                          </h3>
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagefour}`}
                            alt="Exterior"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagefour}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {dashboardimage && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimage}`}
                        >
                          <h3 className="vehicle-info-heading pt-3">
                            Damage Image
                          </h3>
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimage}`}
                            alt="Dashboard"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimage}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {dashboardimagetwo && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagetwo}`}
                        >
                          <h3 className="vehicle-info-heading pt-3">
                            Damage Image
                          </h3>
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagetwo}`}
                            alt="Dashboard"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagetwo}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {dashboardimagethree && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagethree}`}
                        >
                          <h3 className="vehicle-info-heading pt-3">
                            Damage Image
                          </h3>
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagethree}`}
                            alt="Dashboard"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagethree}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {dashboardimagefour && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagefour}`}
                        >
                          <h3 className="vehicle-info-heading pt-3">
                            Damage Image
                          </h3>
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagefour}`}
                            alt="Dashboard"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagefour}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {rimsimage && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimage}`}
                        >
                          <h3 className="vehicle-info-heading pt-3">
                            Rims Image
                          </h3>
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimage}`}
                            alt="Rims"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimage}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {rimsimagetwo && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagetwo}`}
                        >
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagetwo}`}
                            alt="Rims"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagetwo}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {rimsimagethree && (
                        <li
                          data-thumb={`https://topdevit.com/clients/carchaser/public/uploads/${Vehicle_Id}/${rimsimagethree}`}
                        >
                          <img
                            src={`https://topdevit.com/clients/carchaser/public/uploads/${Vehicle_Id}/${rimsimagethree}`}
                            alt="Rims"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagethree}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {rimsimagefour && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagefour}`}
                        >
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagefour}`}
                            alt="Rims"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagefour}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div>
                {vechiclevideo && vechiclevideo !== "null" && (
                  <div className="col-md-12 p-0">
                    <div id="vechiclevideo">
                      <h2>Vehicle Video</h2>
                      <div
                        onClick={() =>
                          openModal(
                            "video",
                            `https://backend.carchaser.ca/videos/${Vehicle_Id}/${vechiclevideo}`
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <video
                          width="250"
                          height="315"
                          controls
                          preload="metadata"
                        >
                          <source
                            src={`https://backend.carchaser.ca/videos/${Vehicle_Id}/${vechiclevideo}`}
                            type="video/mp4"
                          />
                          <source
                            src={`https://backend.carchaser.ca/videos/${Vehicle_Id}/${vechiclevideo}`}
                            type="video/quicktime"
                          />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </div>
                  </div>
                )}
                {/* Modal Implementation */}
                {isModalOpen && (
                  <div className="modal-overlay" onClick={closeModal}>
                    <div
                      className="modal-content"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <span className="close-button" onClick={closeModal}>
                        &times;
                      </span>

                      {/* Add Previous and Next Arrows */}
                      <button
                        className="prev-arrow"
                        onClick={() => navigateImage("prev")}
                      >
                        &#10094;
                      </button>

                      {modalContent.type === "video" ? (
                        <video
                          width="100%"
                          height="100%"
                          controls
                          preload="metadata"
                        >
                          <source src={modalContent.src} type="video/mp4" />
                          <source
                            src={modalContent.src}
                            type="video/quicktime"
                          />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        modalContent.src && (
                          <img
                            src={modalContent.src}
                            alt="Vehicle"
                            style={{ width: "100%", height: "auto" }}
                          />
                        )
                      )}

                      {/* Add Next Arrow */}
                      <button
                        className="next-arrow"
                        onClick={() => navigateImage("next")}
                      >
                        &#10095;
                      </button>
                    </div>
                  </div>
                )}

                {/* CSS for modal styling */}
                <style jsx>{`
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  .modal-content {
    position: relative;
    width: 90%;
    max-width: 1000px;
    background-color: #fff;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .close-button {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    cursor: pointer;
      z-index: 99;
  }
  .modal-content img {
    width: auto !important;
    height: 100% !important;
    margin: auto !important;
  }

  /* Add styles for navigation arrows */
  .prev-arrow, .next-arrow {
    position: absolute;
    top: 50%;
    z-index: 1001;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    font-size: 30px;
    cursor: pointer;
    transform: translateY(-50%);
  }
  .prev-arrow {
    left: 10px;
  }
  .next-arrow {
    right: 10px;
  }
`}</style>
              </div>
            </div>
            <div class="col-lg-6 mb-lg-0 mb-4">
              <div class="vehicle-details-panel">
                <h3 class="headline p-2">Vehicle Details</h3>
                <div class="row">
                  <div class="col-md-6">
                    <div class="details-info-one">
                      <ul class="list">
                        <li>
                          <h4 class="info-headline">Location:</h4>
                          <span class="info">{location}</span>
                        </li>
                        <li>
                          <h4 class="info-headline">Vin Number:</h4>
                          <span class="info">{vin}</span>
                        </li>
                        <li>
                          <h4 class="info-headline">Year:</h4>
                          <span class="info">{year}</span>
                        </li>
                        <li>
                          <h4 class="info-headline">Make:</h4>
                          <span class="info">{make}</span>
                        </li>
                        <li>
                          <h4 class="info-headline">Model:</h4>
                          <span class="info">{model}</span>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="details-info-one">
                      <ul class="list">
                        <li>
                          <h4 class="info-headline">Trim :</h4>
                          <span class="info">{trim}</span>
                        </li>
                        <li>
                          <h4 class="info-headline">Transmission :</h4>
                          <span class="info">{sellertransmission}</span>
                        </li>
                        <li>
                          <h4 class="info-headline">Odometer :</h4>
                          <span class="info">{mileage.toLocaleString()}</span>
                        </li>
                        <li>
                          <h4 class="info-headline">Color :</h4>
                          <span class="info">{color}</span>
                        </li>
                        <li>
                          <h4 class="info-headline">Keys :</h4>
                          <span class="info">{keys}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="milaage-msg">
                  {numericMileage > 165000 && (
                    <p className="vehicle-info-heading pt-3 text-danger">
                      this car will be sold as is
                    </p>
                  )}
                </div>
                <div class="row m-0">
                  <h4 class="info-headline">External Damage to the vehicle.</h4>
                  <span class="info-value w-100 mb-3">{featuresStringtwo}</span>

                  <h4 class="info-headline">Any interior damage?</h4>
                  <span class="info w-100 mb-3">{featuresStringthree}</span>
                  <h4 class="info-headline">Additional car features</h4>
                  <span class="info w-100 mb-3">
                    <strong>{selectedFeatures}</strong>
                  </span>
                  <div className="estimate-average-new pt-3">
                    <div className="row">
                      <div className="col-lg-4 col-4 fw-bold">
                        <div className="circle-grey mb-2">
                          <span class="first-disc"></span>
                        </div>
                        <p>low</p>
                        <p>
                          $
                          {setLowPrice || setLowPrice === 0
                            ? setLowPrice.toLocaleString()
                            : "N/A"}
                        </p>
                      </div>
                      <div className="col-lg-4 col-4 fw-bold text-center">
                        <div className="circle-grey mb-2">
                          <span class="second-disc"></span>
                        </div>
                        <p>average</p>
                        <p>
                          $
                          {setAveragePrice || setAveragePrice === 0
                            ? setAveragePrice.toLocaleString()
                            : "N/A"}
                        </p>
                      </div>
                      <div className="col-lg-4 col-4 fw-bold text-right">
                        <div className="circle-grey mb-2">
                          <span class="third-disc"></span>
                        </div>
                        <p>awesome</p>
                        <p>
                          $
                          {setAwesomePrice || setAwesomePrice === 0
                            ? setAwesomePrice.toLocaleString()
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>

                  <h4 class="info-headline w-100">
                    Our top priority is to sell your car.
                  </h4>
                  <div class="details-info-two">
                    <ul class="list">
                      <li>
                        <p>Do you smoke in this vehicle?</p>
                        <span class="value">{smokeInVehicle}</span>
                      </li>
                      <li>
                        <p>Do you have original factory rims?</p>
                        <span class="value">{hasOriginalRims}</span>
                      </li>

                      <li>
                        <p>Have you replaced your tires in last 12 months?</p>
                        <span class="value">{tireReplacement}</span>
                      </li>

                      <li>
                        <p>Is your car driveable?</p>
                        <span class="value">{vehicleDrivable}</span>
                        <span class="value">{vehicledrivabledesc}</span>
                      </li>

                      <li>
                        <p>Any crack on the windshield?</p>
                        <span class="value">{crackOnWindshield}</span>
                      </li>

                      <li>
                        <p>Do you have any extended warranty?</p>
                        <span class="value">{extendedWarranty}</span>
                      </li>

                      <li>
                        <p>Are you interested in a trade In?</p>
                        <span class="value">{tradeInInterest}</span>
                      </li>

                      <li>
                        <p>Why are you selling?</p>
                        <span class="value">{DonotNeedCar}</span>
                        <span class="value">{MechElectIssues}</span>
                        <span class="value">{DownSize}</span>
                        <span class="value">{BuyAnotherCar}</span>
                      </li>

                      <li>
                        <p>Describe the condition of the car</p>
                        <span class="value">{carCondition}</span>
                      </li>

                      <li>
                        <p>Any Accident Claims? How Much?</p>
                        <span class="value">{accidentclaims}</span>
                        <span class="value">{howmuchclaims}</span>
                      </li>

                      <li>
                        <p>What Rims Are On The Car?</p>
                        <span class="value">{carrims}</span>
                      </li>

                      <li>
                        <p>Do You Have Stock Rims?</p>
                        <span class="value">{stockrim}</span>
                      </li>
                      <li>
                        <p>Any Issues With The Vehicle?</p>
                        <span class="value">{issuevichle}</span>
                        <span class="value">{issuevichledecs}</span>
                      </li>
                      <li>
                        <p>Are There Any Modifications On The Car?</p>
                        <span class="value">{carmodify}</span>
                        <span class="value">{carmodifydecs}</span>
                      </li>
                      <li>
                        <p>Is The Car Leased Or Financed?</p>
                        <span class="value">{financed}</span>
                        <span class="value">{financeddesc}</span>
                      </li>
                      <li>
                        <p>Car Notes</p>
                        <span class="value">{vechiclenotes}</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="report-section">
                  <div class="car-report">
                    {imageUrl ? (
                      <img
                        class="mr-3"
                        src={imageUrl}
                        alt="Badge"
                        style={{
                          width: "200px",
                          height: "auto",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          // Check if evaluation report is available
                          if (
                            !CarfaxReportHideLiensUrl ||
                            CarfaxReportHideLiensUrl === ""
                          ) {
                            alert("Your CarFax report is not available.");
                          } else if (
                            !CarfaxReportHideLiensUrl ||
                            CarfaxReportHideLiensUrl.includes("null")
                          ) {
                            // Check if the dynamic link contains "null"
                            alert("Your CarFax report is not available.");
                          } else {
                            window.open(CarfaxReportHideLiensUrl, "_blank");
                          }
                        }}
                      />
                    ) : (
                      <p>{message}</p>
                    )}
                    <h4 class="info-headline ml-auto">{hasline}</h4>
                  </div>
                </div>

                <div class="alert-panel mt-3">
                  <h4 class="info-headline mb-0">
                    <i class="fa-regular fa-bell "></i> Get Alerts on similar
                    vehicles{" "}
                  </h4>
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    onClick={setAlert}
                  >
                    Set Alert
                  </button>
                </div>
              </div>
            </div>

            <div class="col-lg-3">
              <h3 class="info-headline mb-3">Bid information </h3>
              <div class="main-bid-panel">
                <div class="bid-value-panel">
                  <h4 class="info-headline mb-3 text-primary">Current Bid:</h4>
                  <div class="bid-value">
                    <img src="../images/fav.png" alt="fav"></img>
                    <span className="value">
                      $
                      {currentBid
                        ? parseFloat(currentBid).toLocaleString()
                        : "N/A"}
                    </span>
                  </div>
                </div>

                <div class="bid-value-panel">
                  <h4 class="info-headline mb-3">Previous Bid:</h4>
                  <div class="bid-value">
                    <img src="../images/fav.png" alt="fav"></img>
                    <span className="value">
                      $
                      {previousBid
                        ? parseFloat(previousBid).toLocaleString()
                        : "N/A"}
                    </span>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                {pricemeet === true && (
                  <p
                    style={{
                      color: "green",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    ✅ Reserve price is met!
                  </p>
                )}
              </div>
              <h4 class="info-headline mb-2 mt-4 text-primary">Bid History</h4>
              <div
                className="table-container"
                style={{
                  maxHeight: topBids.length > 5 ? "300px" : "auto", // Set max height if more than 5 records
                  overflowY: topBids.length > 5 ? "auto" : "visible", // Enable scroll if more than 5 records
                }}
              >
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="text-uppercase">
                        Bid Amount
                      </th>
                      <th scope="col" className="text-uppercase">
                        Bid Date-Time
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {topBids.map((bid, index) => (
                      <tr key={index}>
                        <th scope="row">
                          <img src="../images/fav.png" alt="fav" /> $
                          {parseFloat(bid.Bid_Amount).toLocaleString()}
                        </th>
                        <td>{bid.Bid_Date}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* {shownow && (
                    <div className="grey-section p-3 mb-3">
                      <h4 className="info-headline mb-2 text-primary">Purchase at the reserve price immediately</h4>
                      <button
                        className="btn btn-primary"
                        onClick={fetchBuyNow}
                        disabled={auctionOverData === true} // Explicitly check for true
                      >
                        Buy Now
                      </button>
                    </div>
                  )} */}
              {isBidTimeClosed ? (
                <div class="border border-danger">
                  <h4 class="text-danger text-center my-4">
                    Your Bid Time is Closed
                  </h4>
                </div>
            ) : (
                <div className="bg-light p-3 flex-wrap">
                  <h4 className="info-headline mb-2 text-primary">
                    Place Your Bid
                  </h4>
                  <div className="bid-amount w-100">
                    <input
                      type="text"
                      className="form-control"
                      value={newBidAmount}
                      onChange={handleBidPriceChange}
                      placeholder="Amount"
                      onKeyDown={handleKeyDown} // Add keydown handler
                    />
                    {isLoading ? "Placing..." : ""}
                    {bidmessage.text && (
                      <p style={{ color: bidmessage.color, fontWeight: "bold" }}>
                        {bidmessage.text}
                      </p>
                    )}
                    {/* Display message instead of alert */}
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={handleAddNewBid}
                    >
                      Place your Bid
                    </button>
                  </div>
                </div>
              )}
              <div class="bg-light p-3 flex-wrap mt-3">
                <h4 class="info-headline mb-2 text-primary">Delivery Mode</h4>
                <div class="bid-amount w-100 ">
                  <div class="option">
                    <div class="form-check me-2">
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="pickup"
                          name="deliveryMode"
                          value="Pickup"
                          checked={deliveryMode === "Pickup"}
                          onChange={() => setDeliveryMode("Pickup")}
                        />
                        <label className="form-check-label" htmlFor="pickup">
                          Pickup
                        </label>
                      </div>
                    </div>
                    <div class="form-check me-2">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="dropoff"
                        name="deliveryMode"
                        value="Drop off"
                        checked={deliveryMode === "Drop off"}
                        onChange={() => setDeliveryMode("Drop off")}
                      />
                      <label className="form-check-label" htmlFor="dropoff">
                        Drop off
                      </label>
                    </div>
                  </div>
                  {deliveryMode === "Pickup" && (
                    <div className="col-lg-9 col-12">
                      <div className="form-average">
                        <p class="mt-2">
                          Car Chaser’s address: 801-1275 Finch Ave W.
                        </p>
                      </div>
                    </div>
                  )}
                  {deliveryMode === "Drop off" && (
                    <div className="col-lg-9 col-12">
                      <div className="form-average">
                        <p class="mt-2">Drop off within the GTA is $165</p>
                      </div>
                    </div>
                  )}
                  <input
                    class="btn btn-primary option-btn"
                    type="submit"
                    value="Save"
                    disabled={auctionOverData === true}
                    onClick={fetchDeliveryModePass}
                  ></input>
                  {deliveryModeMessage && (
                    <p className="text-success mt-3">{deliveryModeMessage}</p>
                  )}
                  {isLoading && <p>Loading...</p>}
                </div>
              </div>
              {successMessage && (
                <div className="alert alert-success mt-3" role="alert">
                  {successMessage}
                </div>
              )}
              <div class="bg-light p-3 flex-wrap mt-3">
                <h4 class="info-headline mb-2 text-primary">
                  Sale Information
                </h4>
                <ul class="report-list">
                  <li>
                    <p class="headline">Sales Rep: </p>
                    <p>
                      {agentDetails
                        ? `${agentDetails.Agent_Fname} ${agentDetails.Agent_Lname}`
                        : "N/A"}
                    </p>
                  </li>
                  <li>
                    <p class="headline">Agent Contact Number: </p>
                    <p>
                      {agentDetails ? `${agentDetails.Agent_Phone}` : "N/A"}
                    </p>
                  </li>
                  <li>
                    <p class="headline">Sale Address: </p>
                    <p>
                      {location},{province}
                    </p>
                  </li>
                </ul>
              </div>
              <div class="ad-section testimonial-section mt-3">
                <h4 class="text-primary mb-3">Why Our Partners Trust Us</h4>
                <div class="flexslider">
                  <ul className="slides">
                    <li>
                      <p>
                        As a car dealer, time is money, and Car Chaser helps me
                        save both. Their platform streamlines the selling
                        process, from listing my inventory to communicating with
                        potential buyers. I appreciate how they prioritize
                        security and verification, giving me peace of mind
                        throughout the transaction. If you’re serious about
                        selling cars efficiently, look no further than Car
                        Chaser.
                      </p>

                      <div class="author-info">
                        <div class="author-image">
                          <img src="../images/t3-1.png"></img>
                        </div>
                        <div class="author-text">
                          <p class="text-primary mb-0">Trusted dealer</p>
                          <p>Bacchus</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <p>
                        I’ve been using Car Chaser for years now, and it’s hands
                        down the best platform for selling cars online. The
                        interface is intuitive, and the listing process is
                        straightforward. Plus, their marketing tools help my
                        listings stand out, attracting more buyers. Whether
                        you’re a seasoned seller or just starting out, I highly
                        recommend Car Chaser for all your car-selling needs.
                      </p>

                      <div class="author-info">
                        <div class="author-image">
                          <img src="../images/t2-1.png"></img>
                        </div>
                        <div class="author-text">
                          <p class="text-primary mb-0">Trusted dealer</p>
                          <p>Adelita</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <p>
                        Car Chaser has revolutionized the way I sell cars! Their
                        platform is user-friendly and efficient, allowing me to
                        reach a wider audience of potential buyers. The support
                        team is responsive and helpful, ensuring a smooth
                        experience every step of the way. Thanks to Car Chaser,
                        I’ve been able to sell my car faster and with ease.
                      </p>

                      <div class="author-info">
                        <div class="author-image">
                          <img src="../images/third.jpg"></img>
                        </div>
                        <div class="author-text">
                          <p class="text-primary mb-0">Trusted dealer</p>
                          <p>Justin Sharpe</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
