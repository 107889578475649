import axios from 'axios';

const api = axios.create({
  baseURL: 'https://backend.carchaser.ca/api', // Base URL for all requests
  
});



export default api;
