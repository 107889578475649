import React, { useState, useEffect , useRef} from "react";
import { SellerNav } from "./DealerNav";
import api from "../api";
import { useNavigate } from "react-router-dom"; // Ensure this is imported
import { useParams } from "react-router-dom"; // import useParams
import Swal from "sweetalert2";
export const NagitionBids = () => {
  const navigate = useNavigate();
  const { Vehicle_Id } = useParams(); // Get the ID from the URL slug
  const dealerId = sessionStorage.getItem("user_id");
  const [bidNegotiations, setBidNegotiations] = useState([]);
  const [vin, setVin] = useState("");
  const [year, setYear] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [trim, setTrim] = useState("");
  const [mileage, setMileage] = useState("");
  const [firmprice, setFirmPrice] = useState("");
  const numericMileage = Number(mileage);
  const numericFirmPrice = Number(firmprice);
  const [exteriorimage, setExteriorImage] = useState("");
  const [exteriorimagetwo, setExteriorImageTwo] = useState("");
  const [exteriorimagethree, setExteriorImageThree] = useState("");
  const [exteriorimagefour, setExteriorImageFour] = useState("");
  const [counterPrice, setCounterPrice] = useState("");
  const [comments, setComments] = useState("");
  const [sellerid, setSeller] = useState("");
  const [highestbid, SetHighFetchDataalwsys] = useState('');
  const numericHighestBid = Number(highestbid);
  const [negotiationHistory, setNegotiationHistory] = useState([]);
  const [timeLeft, setTimeLeft] = useState({ hours: 0, minutes: 0, seconds: 0 });
const [Buttondisable, setIsButtondisable] = useState(""); 
const [delermessage, setShowdelermessage] = useState('');
const prevDealerIdRef = useRef(null); // Track previous dealer ID
const intervalRef = useRef(null);
const remainingTimeRef = useRef(0);
  const formatCurrency = (value) => {
    if (!value) return '';
    const numericValue = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    const formattedValue = `$${Number(numericValue).toLocaleString()}`; // Format with $ and commas
    return formattedValue;
  };
  useEffect(() => {
    const interval = setInterval(() => {
        BidFetchData();
    }, 1000); // Fetch data every second

    return () => clearInterval(interval); // Cleanup the interval on component unmount
}, []); // Empty dependency array ensures this runs only once on mount

const BidFetchData = async () => {
  try {
      const response = await api.get(`/show-negotiation/${Vehicle_Id}`);
      const data = response.data.bidnegotation;

      console.log('Bid Negotiations Data:', data);

      // Check if data is null or empty
      if (!data || data.length === 0) {
          console.log('The seller has declined the negotiation offer.');
          setIsButtondisable(true); // Set bid time closed when timer hits 0
          setShowdelermessage('The seller has declined the negotiation offer.');
          navigate('/future-cars');
          return; // Exit function early
      }

      // Process data when it's not null or empty
      const currentDealerId = data[0]?.dealer_id;

      // Check if dealer ID has changed
      if (prevDealerIdRef.current !== null && prevDealerIdRef.current !== currentDealerId) {
          console.log(
              `Dealer ${prevDealerIdRef.current} rejected your car price. Now moving to Dealer ${currentDealerId}.`
          );
          setIsButtondisable(true); // Set bid time closed when timer hits 0
          setShowdelermessage('The seller has declined the negotiation offer.');
      }

      // Update previous dealer ID
      prevDealerIdRef.current = currentDealerId;
      console.log('Updated previous dealer ID:', prevDealerIdRef.current);

      // Update state with fetched data
      setBidNegotiations(data);
  } catch (error) {
      console.error('Error fetching Bid details:', error);
  }
};

  // Separate records into your negotiation and other dealer's negotiation
  const yourNegotiation = bidNegotiations.find(
    (negotiation) => negotiation.dealer_id === Number(dealerId)
  );
  const otherNegotiation = bidNegotiations.find(
    (negotiation) => negotiation.dealer_id !== Number(dealerId)
  );
  const handleAction = async (actionType) => {
    if (yourNegotiation) {
      // Define the confirmation messages
      let confirmationMessage = "";
      if (actionType === "Accept") {
        confirmationMessage = `Do you want to Accept this offer?`;
      } else if (actionType === "Decline") {
        confirmationMessage = "Do you want to Decline this offer?";
      }
      const result = await Swal.fire({
        //title: "Confirmation",
        text: confirmationMessage,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
  
      // Show the confirmation dialog
      // const isConfirmed = window.confirm(confirmationMessage);
  
      if (!result.isConfirmed) {
        console.log("Action cancelled by the user.");
        return; // Exit the function if the user cancels the action
      }
  
      // Prepare the payload
      const payload = {
        BidID: yourNegotiation.bid_id,
        VehicleID: yourNegotiation.vehicle_id,
        DealerID: yourNegotiation.dealer_id,
        SellerID: yourNegotiation.seller_id,
        CounterPrice: yourNegotiation.dealer_counter_offer || yourNegotiation.seller_counter_offer,
        Comments: yourNegotiation.dealer_comments || "No comments",
        Action: actionType, // Accept or Decline
        PortalName: "Dealer",
      };
  
      // Send the API request
      try {
        const response = await api.post("/negotiation", payload);
        console.log("Response from server:", response);
  
        if (actionType === "Decline") {
          // Display the message and navigate
          alert("The negotiation process is complete! Check out our upcoming vehicles and get ready to place your bids on the next great ride!");
          navigate("/future-cars"); // Redirect to the desired route
        }
  
        // Optionally refresh data after action
        BidFetchData();
      } catch (error) {
        console.error("Error submitting negotiation action:", error);
      }
    }
  };
  const handlePriceChange = (e) => {
    const rawValue = e.target.value;
    setCounterPrice(formatCurrency(rawValue)); // Update state with formatted value
  };
  const handleNewCounterPrice = () => {
    if (yourNegotiation) {
      const negotiationData = {
        bidId: yourNegotiation.bid_id,
        dealerId: yourNegotiation.dealer_id,
        sellerId: yourNegotiation.seller_id,
        vehicleId: yourNegotiation.vehicle_id,
      };
      
      // Navigate to the negotiation popup page with negotiation data
      //navigate("/nagition-bid-pop", { state: negotiationData });
    }
  };

  const fetchData = async () => { 
    try {
    const response = await api.get(`/seller/vehicle/${Vehicle_Id}`);
    const vehicleData = response.data;
    setVin(vehicleData.vehicle.VIN || "");
    setYear(vehicleData.vehicle.Year || "");
    setMake(vehicleData.vehicle.Make || "");
    setModel(vehicleData.vehicle.Model || "");
    setTrim(vehicleData.vehicle.Trim || "");
    setMileage(vehicleData.vehicle.Mileage || "");
    setFirmPrice(vehicleData.vehicle.Reserve_Price);
    setSeller(vehicleData.vehicle.Seller_Id);
    setExteriorImage(vehicleData.vehicle["Exterior_Image"] || "");
    setExteriorImageTwo(vehicleData.vehicle["Exterior_Image2"] || "");
    setExteriorImageThree(vehicleData.vehicle["Exterior_Image3"] || "");
    setExteriorImageFour(vehicleData.vehicle["Exterior_Image4"] || "");
  } catch (error) {
  console.error("Error fetching vehicle details:", error);
  }
};
useEffect(() => {
  fetchData();
  }, []);
 
  const handleSubmit = async () => {
    if (!counterPrice) {
      Swal.fire({
        title: "Error",
        text: "Please add a counter price.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return; // Exit the function
    }
  
    if (!yourNegotiation) {
      Swal.fire({
        title: "Error",
        text: "Negotiation data is missing.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return; // Exit the function
    }
  
    // Extract necessary values from yourNegotiation
    const { bid_id: bidIds, dealer_id: dealerIds, seller_id: sellerid, vehicle_id: vehicleId } = yourNegotiation;
  
    const data = {
      BidID: bidIds,
      VehicleID: vehicleId,
      DealerID: dealerIds,
      SellerID: sellerid,
      CounterPrice: parseFloat(counterPrice.replace(/[$,]/g, '')), // Numeric value only
      Comments: comments && comments.trim() !== '' ? comments : null, // Null if comments are invalid
      Action: "reply",
      PortalName: "Dealer",
    };
  
    try {
      // Parse the numeric value from the counterPrice string
      const numericPrice = parseFloat(counterPrice.replace(/[$,]/g, ''));
      if (numericPrice <= numericHighestBid) {
        Swal.fire({
          title: "Invalid Counter Offer",
          text: `The counter offer must be higher than the highest bid of $${numericHighestBid.toLocaleString("en-US")}.`,
          icon: "error",
          confirmButtonText: "OK",
        });
        return; // Exit the function
      }
  
      // Show confirmation dialog with the dynamic price
      const isConfirmed = await Swal.fire({
        title: "New Reserve Price",
        text: `Is $${numericPrice.toLocaleString()} your new reserve price?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
  
      if (isConfirmed.isConfirmed) {
        // Proceed with submitting the negotiation if the user confirms
        const response = await api.post('/negotiation', data);
        console.log('API Response:', response.data);
        Swal.fire("Success", "Negotiation submitted successfully.", "success");
        setCounterPrice("");
        setComments("");
        fetchAllChat();
      } else {
        Swal.fire("Cancelled", "Negotiation submission canceled.", "info");
      }
    } catch (error) {
      console.error('Error submitting negotiation:', error);
      Swal.fire("Error", "Error submitting negotiation.", "error");
    }
  };
  
  
  const fetchAllChat = async () => {
    try {
      const response = await api.get(`/negotiation-history/${Vehicle_Id}/${dealerId}`);
      const data = response.data.negotiations_history1 || [];
      setNegotiationHistory(data);
    } catch (error) {
      console.error("Error fetching negotiation history:", error);
    }
  };

  useEffect(() => {       
    fetchAllChat();
  }, []);
  useEffect(() => {
    if (Vehicle_Id) {
      fetchAllChat();
      const interval = setInterval(fetchAllChat, 5000); // Fetches every 5 seconds
      return () => clearInterval(interval); // Clean up on unmount
    }
  }, [Vehicle_Id]);
  const fetchalwayshighest = async () => {
    try {
      const response = await api.get(`/dealer/highestbid/${Vehicle_Id}`);
      const data = response.data;
      console.log("testhighestbid",response.data)
      SetHighFetchDataalwsys(data.HighestBid);
    } catch (error) {
     // console.error('Error fetching bid history:', error);
    }
  };

  useEffect(() => {
    fetchalwayshighest();
  }, []); // Empty array ensures this runs only once on component mount

  useEffect(() => {
    fetchAuctionTimeout();
  }, []);
  const fetchAuctionTimeout = async () => {
   try {
     const response = await api.get(`/negotiationtimeoutdetails/${Vehicle_Id}`);
     console.log(`API Response for Vehicle ${Vehicle_Id}:`, response.data);
     const totalSeconds = Math.floor(response.data.counterseconds);
     if (intervalRef.current === null) {
       startCountdown(totalSeconds); // Start countdown only if it's not already running
     } else {
       remainingTimeRef.current = totalSeconds; // If already running, just update the remaining time
     }
   
   } catch (error) {
     console.error(`Error fetching auction timeout for Vehicle ${Vehicle_Id}:`, error);
     setTimeLeft({ hours: 0, minutes: 0, seconds: 0 });
   }
 };
 useEffect(() => {
   const intervalId = setInterval(() => {
     fetchAuctionTimeout();
   }, 2000);
 
   // Cleanup interval when component unmounts
   return () => clearInterval(intervalId);
 }, [Vehicle_Id]); // Dependency array ensures firmprice is up-to-date
 
 const startCountdown = (totalSeconds) => {
   let secondsRemaining = totalSeconds;
   remainingTimeRef.current = secondsRemaining;
 
   intervalRef.current = setInterval(() => {
     if (secondsRemaining <= 0) {
       clearInterval(intervalRef.current);
       intervalRef.current = null;
       setTimeLeft({ hours: 0, minutes: 0, seconds: 0 });
       setIsButtondisable(true); // Set bid time closed when timer hits 0
       return;
     }
 
     secondsRemaining -= 1;
     remainingTimeRef.current = secondsRemaining;
 
     const hours = Math.floor(secondsRemaining / 3600);
     const minutes = Math.floor((secondsRemaining % 3600) / 60);
     const seconds = secondsRemaining % 60;
 
     setTimeLeft({ hours, minutes, seconds });
   }, 1000);
 };
 useEffect(() => {
   const fetchTimeoutAndStartTimer = async () => {
     await fetchAuctionTimeout();
 
     // Start countdown timer
     const timer = setInterval(() => {
       setTimeLeft((prev) => {
         const totalSeconds = prev.hours * 3600 + prev.minutes * 60 + prev.seconds - 1;
 
         if (totalSeconds <= 0) {
           clearInterval(timer);
           return { hours: 0, minutes: 0, seconds: 0 };
         }
 
         const hours = Math.floor(totalSeconds / 3600);
         const minutes = Math.floor((totalSeconds % 3600) / 60);
         const seconds = totalSeconds % 60;
 
         return { hours, minutes, seconds };
       });
     }, 1000);
 
     return () => clearInterval(timer); // Cleanup timer on unmount
   };
 
   fetchTimeoutAndStartTimer();
 }, []);
  return (
    <section className="car-details">
      <SellerNav />
      <div class="nego">
        <div class="container py-5">
        {delermessage && (
          <div class="container">
              <div class="row">
                  <div class="col-md-12 text-center">
                      <div class="alert alert-success mt-2">
                          <strong>{delermessage}</strong> 
                      </div>
                    </div>
              </div>
          </div>
        )}
          <div class="row">
              <div class="car-profile">
              <div class="car-image">
                  {exteriorimage && (
                        <img
                           src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimage}`}
                           alt="Exterior"
                           />
                     )}
                     {exteriorimagetwo && (
                    
                        <img
                           src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagetwo}`}
                           alt="Exterior"
                          
                           />
                     )}
                     {exteriorimagethree && (
                    
                        <img
                           src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagethree}`}
                           alt="Exterior"
                           />
                     )}
                     {exteriorimagefour && (
                        <img
                           src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagefour}`}
                           alt="Exterior"
                           />
                     )}
                  </div>
                  <ul class="profile-list">
                    <li><span>Make:</span> {make}</li>
                    <li><span>Model:</span> {model}</li>
                    <li><span>Year:</span> {year}</li>
                    <li><span>Odometer:</span> {numericMileage.toLocaleString()} Km</li>
                    <li><span>Price:</span> ${numericFirmPrice.toLocaleString()}</li>
                  </ul>
              </div>

              <div class="negotaition-panel">
                <div class="inner-panel p-4">
                  <h3 class="main-headline text-uppercase">Welcome to Car Bidding Negotiation</h3>
                  <p class="tag-line text-center">Negotiate with the seller and finalize your deal.</p>
                  <div class="message-panel">
                  <ul className="list">
        {negotiationHistory.map((negotiation) => (
          <li key={negotiation.id} className={negotiation.sender_role}>
            {negotiation.sender_role === "seller" ? (
              <div className="seller-pop">
                <div className="amount">${negotiation.conter_offer.toLocaleString()}</div>
                <p className="comments">{negotiation.message}</p>
                <small>Seller (ID: {negotiation.sender_id})</small>
              </div>
            ) : (
              <div className="dealer-pop">
                <div className="amount">${negotiation.conter_offer.toLocaleString()}</div>
                <p className="comments">{negotiation.message}</p>
                <small>Dealer (ID: {negotiation.sender_id})</small>
              </div>
            )}
          </li>
        ))}
      </ul>
                  </div>

                  <div class="input-section bg-light p-4">
                    <label class="w-100">Counter Amount</label>
                    <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    value={counterPrice}
                    onChange={handlePriceChange} // Update with formatted value
                  />

                    <label class="mt-4 w-100">Comments</label>
                    <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="5"
                    placeholder="Comments"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)} // Update state on textarea change
                  ></textarea>

                    <button className="btn btn-primary mt-4" onClick={handleSubmit} disabled={Buttondisable}>
                    Submit
                  </button>
                  </div>

                  <div class="input-section bg-light p-4 mt-4">
                    <h6 class="text-center mb-3">Finalize Your Offer</h6>
                    <div class="row">
                      <div class="col-6">
                        <button class="btn btn-success form-control" disabled={Buttondisable}  onClick={() => handleAction("Accept")}>Accept Offer</button>
                      </div>
                      <div class="col-6">
                        <button class="btn btn-danger form-control " disabled={Buttondisable}  onClick={() => handleAction("Decline")}>Decline Offer</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
};
